$(document).ready(function() {
  var useItemH = $('#lounge_box').innerHeight();
  var useAreaH = window.innerHeight;
  onResize(useAreaH);

  var config = {
    apiKey: "AIzaSyBj4TLA-gzkUXU9bmZUTUaoUucYSuaS3bk",
    authDomain: "musicdb2-20191127.firebaseapp.com",
  };

  firebase.initializeApp(config);
  initApp();

  $(window).resize(function() {
    useAreaH = window.innerHeight;
    onResize(useAreaH);
  });

  function onResize(el){
    if((useAreaH - 120) >= useItemH){
      $('#lounge_frame').addClass('is_cover');
      $('#lounge_frame').css({'height': (useAreaH - 40) + 'px'});
    }else{
      $('#lounge_frame').removeClass('is_cover');
    }
  }

  // Firebae 認証
  function initApp() {
    firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
            location.href = "/reports";
        } else {
            console.log("ログインしていない");
        }
    });

    if (document.getElementById('signin') != null) {
        // ログインページ
        document.getElementById('signin').addEventListener('click', toggleSignIn, false);
    } else if (document.getElementById('send_reset_email') != null) {
        // 再発行メール送信画面
        document.getElementById('send_reset_email').addEventListener('click',sendResetEmail, false);
    } else if (document.getElementById('edit_password') != null) {
        // パスワード再設定画面
        // アクセスした時点でoobCodeが切れていたりしたらエラーを出す。
        const urlParams = new URLSearchParams(window.location.search);
        const oobCode = urlParams.get('oobCode');

        firebase.auth().verifyPasswordResetCode(oobCode).then(function (){
            document.getElementById('edit_password').addEventListener('click', updatePassword, false);
        }).catch(function(error){
            // oobCode期限切れor不正
            $('#service-approval_box').prepend('<p id="service-approval-error">URLの有効期限が切れているか、URLの形式が不正です。メールに記載のリンクからアクセスし直すか、パスワード再設定メールを再度送信してください。</p>');
            $('#info-text').hide();
            $('#input-form').hide();
        })

    }
  }

  function toggleSignIn() {
    //let tenantId = document.getElementById('tenant-id').value;
    let email = document.getElementById('email').value;
    let password = document.getElementById('password').value;

    // テナントIDが入力されている場合
    // テナント管理型のログインを試みる
    //if (tenantId) {
    //  firebase.auth().tenantId = tenantId;
    //}

    $('#service-approval_box').children("p").remove();

    firebase.auth().signInWithEmailAndPassword(email, password).then(function(user) {
        location.href = '/reports';
    }).catch(function(error) {
        console.log(error.message);
        firebase.auth().tenantId = null;
        $('#service-approval_box').prepend('<p id="service-approval-error">ログイン情報が正しくありません</p>');
    });
  }

  function sendResetEmail() {
      // 既に表示されているエラーメッセージがあったら削除する（service-approval-error）
      $('#service-approval-error').remove();

      let email = document.getElementById('email').value;
      let actionCodeSettings = {
          // 環境に応じたURLをパラメータに持たせ、CloudFunctions(password_edit_accessor)でリダイレクトさせる（dynamiclinkdomainは仕事しなかった）
          url: window.location.origin + "/accounts/edit_password"
      }
      firebase.auth().sendPasswordResetEmail(email, actionCodeSettings).then(function(){
          $('#info-text').text("パスワード再設定メールを送信しました。メールに記載の内容に従って、パスワードを再設定してください。");
          $('#input-form').hide();
      }).catch(function(error) {
          let message = "";
          switch (error.code) {
              case "auth/invalid-email":
              case "auth/user-not-found":
                  message = "メールアドレスに誤りがあるか、登録されていないメールアドレスです。";
                  break;
              default:
                  message = "エラーが発生しました。";
          }
          $('#service-approval_box').prepend('<p id="service-approval-error">' + message + '</p>');
      });
  }

  function updatePassword(){
      $('#service-approval-error').remove();
      const urlParams = new URLSearchParams(window.location.search);
      const oobCode = urlParams.get('oobCode');

      firebase.auth().verifyPasswordResetCode(oobCode).then(function(){
          let password = document.getElementById('password').value;
          let password_confirm = document.getElementById('password_confirm').value;
          if (password == "" || password_confirm == "") {
              // 未入力あり
              $('#service-approval_box').prepend('<p id="service-approval-error">「新しいパスワード」「新しいパスワード（確認）」は両方入力してください。</p>');
          } else if (password != password_confirm) {
              // パスワード不一致
              $('#service-approval_box').prepend('<p id="service-approval-error">「新しいパスワード」「新しいパスワード（確認）」が一致しません。</p>');
          } else if (password.length < 12) {
              // 12文字に満たない
              $('#service-approval_box').prepend('<p id="service-approval-error">パスワードは12文字以上で設定してください。</p>');
          } else {
              firebase.auth().confirmPasswordReset(oobCode, password).then(function(){
                  $('#info-text').text("パスワードを変更しました。変更後のパスワードで再度ログインしてください。");
                  $('#input-form').hide();
              }).catch(function(error){
                  switch (error.code) {
                      case "auth/expired-action-code":
                      case "auth/invalid-action-code":
                          $('#service-approval_box').prepend('<p id="service-approval-error">URLの有効期限が切れているか、URLの形式が不正です。メールに記載のリンクからアクセスし直すか、パスワード再設定メールを再度送信してください。</p>');
                          $('#info-text').hide();
                          $('#input-form').hide();
                          break;
                      default:
                          $('#service-approval_box').prepend('<p id="service-approval-error">エラーが発生しました。</p>');
                  }
              });
          }
      }).catch(function(error){
          // oobCode期限切れor不正
          $('#service-approval_box').prepend('<p id="service-approval-error">URLの有効期限が切れているか、URLの形式が不正です。メールに記載のリンクからアクセスし直すか、パスワード再設定メールを再度送信してください。</p>');
          $('#info-text').hide();
          $('#input-form').hide();
      })
  }


});
